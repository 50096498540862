// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBCHfKHUwauP3g6a5QmJ_zXEH2hSCTCZ5A",
  authDomain: "personalchat-5892d.firebaseapp.com",
  projectId: "personalchat-5892d",
  storageBucket: "personalchat-5892d.appspot.com",
  messagingSenderId: "489361100479",
  appId: "1:489361100479:web:e7feae8d1f54862966c00a"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const goggleAuthProvider = new GoogleAuthProvider();

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(firebaseApp);

export { auth, goggleAuthProvider, db };
