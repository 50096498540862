import React, { useEffect } from "react";
import { useState } from "react";

const BotResponse = ({ response, chatLogRef, sectionRef }) => {
  const [botResoponse, setBotResponse] = useState("");
  const [isPrinting, setIsPrinting] = useState(true);
  const [isButtonVisible, setIsButtonVisible] = useState(false);

  useEffect(() => {
    
    let index = 1;
    let msg;

function updateBotResponse() {
  if (response !== " - The Ultimate AI Assistant") {
    setIsButtonVisible(true);
  }

  if (!isPrinting) {
    return;
  }

  setBotResponse(response.slice(0, index));

  if (index >= response.length) {
    setIsButtonVisible(false);
    
  } else {
    index += 5;
  }

  //scrollToBottom();
  msg = requestAnimationFrame(updateBotResponse);
  
}

msg = requestAnimationFrame(updateBotResponse);

    return () => clearInterval(msg); // clear interval on component unmount
  }, [chatLogRef, response, isPrinting]);

  const stopPrinting = () => setIsPrinting(!isPrinting);
  const sendMessage = () => {
    window.open(`https://mail.google.com/mail/?view=cm&fs=1&to=&body=${response}`, '_blank');
  }
  const copy = () => {

    navigator.clipboard.writeText(`${response}`)
    .then(() => {
        console.log('Text successfully copied to clipboard!');
    })
    .catch(err => {
        console.error('Error trying to copy text: ', err);
    });


  }

  return (
    <>
      <pre>
        {botResoponse}
        {botResoponse === response ? "" : "|"}
      </pre>
      {isButtonVisible && (
        <button className="stop-messgage" onClick={stopPrinting}>
          {isPrinting ? "Stop Message" : "Regenerate Message"}
        </button>
      )}
      {
        !isButtonVisible && (
          <>
          <button className="stop-messgage" onClick={copy}>
            Copy to Clipboard
          </button>
          {/* <button className="stop-messgage" onClick={sendMessage}>
          Open in Gmail
        </button> */}
          </>
        )
      }
    </>
  );
};

export default BotResponse;
