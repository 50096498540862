import React, { useEffect, useRef, useState } from "react";
import Avatar from "../components/Avatar";
import BotResponse from "../components/BotResponse";
import Error from "../components/Error";
import Loading from "../components/Loading";
import SvgComponent from "../components/SvgComponent";
import TeachingUI from "../components/TeachingUI";
import 'bootstrap/dist/css/bootstrap.css';
import {sharedHeader} from "../components/TeachingUI"

const Main = () => {

  const messagePromptEngineering =  `You are a bot who's job is to help users craft messages given prompt, do not include anything else in the response, do not ask user anything just construct a paragraph based on the message user wants to write as expressed in prompt`;
  const emailPromptEngineering = `You are a bot who's job is to help users craft emails given prompt, do not include anything else in the response, write short emails`;
  const greetingPromptEngineering =  `You are a bot who's job is to help users craft greetings given prompt, do not include anything else in the response, do not ask user anything just construct a greeting based on the message user wants to write as expressed in prompt, add emojis if appropriate`;
  const wikiPromptEngineering = `You are a wikipedia bot, users will ask you questions, help them with short and concise answers`;

  const [messages, setMessages] = useState([{ "role": "system", "content": messagePromptEngineering }])
  const [showMenu, setShowMenu] = useState(false);
  const [changeMode, setChangeMode] = useState("W");
  const [inputPrompt, setInputPrompt] = useState("");
  const [chatLog, setChatLog] = useState([]);
  const [err, setErr] = useState(false);
  const [responseFromAPI, setReponseFromAPI] = useState(false);

  const [selectedOption, setSelectedOption] = useState("greeting");
  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  useEffect(()=>{
    setChatLog([])
    if(selectedOption === 'wiki'){
      setMessages([{"role":"system", "content": wikiPromptEngineering}])
    }
    else if(selectedOption === 'greeting'){
      setMessages([{"role":"system", "content": greetingPromptEngineering}])
    }
    else if(selectedOption === 'message'){
      setMessages([{ "role": "system", "content": messagePromptEngineering }]) 
    } else{
      setMessages([{ "role": "system", "content": emailPromptEngineering }])
    }
  },[selectedOption])

  const getContent = () => {
    return selectedOption === "messages" ? `You are a bot who's job is to help users craft emails given prompt, do not include anything else in the response, write short emails`: `You are a bot who's job is to help users craft messages given prompt, do not include anything else in the response, write short messages`
  }

  const sectionRef = useRef();

  const chatLogRef = useRef(null);

  const handleSubmit = (e) => {
    if (!!e)
      e.preventDefault();

    if (!responseFromAPI) {
      if (inputPrompt.trim() !== "") {

        setReponseFromAPI(true);
        const userState = { "role": "user", "content": inputPrompt };
        const messageState = [...messages, userState]; //sys gen + user input
        setChatLog([...chatLog, { chatPrompt: userState.content }]);
        console.log("pre message state");
        console.log(messageState);
        setMessages(messageState);
        callAPI(messageState, userState);

        // hide the keyboard in mobile devices
        e.target.querySelector("input").blur();
      }

      async function callAPI(messageState, updatedChatState) {
        try {

          const response = await fetch("https://asia-south1-quickgpt196463.cloudfunctions.net/app", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ messages: messageState }),
          });
          const data = await response.json();
          setChatLog([
            ...chatLog,
            {
              chatPrompt: updatedChatState.content,
              botMessage: data.resp.content,
            },
          ]);
          console.log("Updated message state");
          console.log([...messageState, data.resp]);
          setMessages([...messageState, data.resp])
          setErr(false);
        } catch (err) {
          setErr(err);
          console.log(err);
        }
        //  Set responseFromAPI back to false after the fetch request is complete
        setReponseFromAPI(false);
      }
    }

    setInputPrompt("");

  };

  const getPlaceholderFromSelectedOption = (selectedOption) => {
    if(selectedOption === "greeting"){
      return "e.g. Wish Katy a happy birthday";

    } else if (selectedOption === "wiki"){
      return "e.g. How many feet is 300 cm";

    } else if (selectedOption === "email"){
      return "e.g. Write an email about ...";

    } else if (selectedOption === "message"){
      return "e.g. Write a message about ...";
    }

  }

  return (
    
    <>
   
      <section style={{flex:1, height: "70vh", overflowY: "auto"}} ref={sectionRef}>
      {sharedHeader(selectedOption, setSelectedOption)}
      {chatLog.length > 0 ? (
          <div style={{"overflow":"auto"}} className="chatLogWrapper">
            {chatLog.length > 0 &&
              chatLog.map((chat, idx) => (
                <div
                  className="chatLog"
                  key={idx}
                  ref={chatLogRef}
                  id={`navPrompt-${chat.chatPrompt.replace(
                    /[^a-zA-Z0-9]/g,
                    "-"
                  )}`}
                >
                  <div className="chatPromptMainContainer">
                    <div className="chatPromptWrapper">
                      <Avatar bg="#5437DB" className="userSVG">
                        <svg
                          stroke="currentColor"
                          fill="none"
                          strokeWidth={1.9}
                          viewBox="0 0 24 24"
                          // strokeLinecap="round"
                          // strokeLinejoin="round"
                          className="h-6 w-6"
                          height={40}
                          width={40}
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                          <circle cx={12} cy={7} r={4} />
                        </svg>
                      </Avatar>
                      <div id="chatPrompt">{chat.chatPrompt}</div>
                    </div>
                  </div>

                  <div className="botMessageMainContainer">
                    <div className="botMessageWrapper">
                      <Avatar bg="#11a27f" className="openaiSVG">
                        <SvgComponent w={41} h={41} />
                      </Avatar>
                      {chat.botMessage ? (
                        <div id="botMessage">
                          <BotResponse
                            response={chat.botMessage}
                            chatLogRef={chatLogRef}
                          />
                        </div>
                      ) : err ? (
                        <Error err={err} />
                      ) : (
                        <Loading />
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <TeachingUI setInputPrompt={setInputPrompt} selectedOption = {selectedOption} setSelectedOption = {setSelectedOption}/>
        )}
      </section>
      <form onSubmit={handleSubmit}>
          <div className="inputPromptWrapper">
            <input
              name="inputPrompt"
              id=""
              placeholder= {getPlaceholderFromSelectedOption(selectedOption)}
              className="inputPrompttTextarea"
              type="text"
              rows="10"
              value={inputPrompt}
              onChange={(e) => setInputPrompt(e.target.value)}
              autoFocus
            ></input>
            <button aria-label="form submit" type="submit">
              <svg
                fill="#ADACBF"
                width={15}
                height={20}
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
                stroke="#212023"
                strokeWidth={0}
              >
                <title>{"submit form"}</title>
                <path
                  d="m30.669 1.665-.014-.019a.73.73 0 0 0-.16-.21h-.001c-.013-.011-.032-.005-.046-.015-.02-.016-.028-.041-.05-.055a.713.713 0 0 0-.374-.106l-.05.002h.002a.628.628 0 0 0-.095.024l.005-.001a.76.76 0 0 0-.264.067l.005-.002-27.999 16a.753.753 0 0 0 .053 1.331l.005.002 9.564 4.414v6.904a.75.75 0 0 0 1.164.625l-.003.002 6.259-4.106 9.015 4.161c.092.043.2.068.314.068H28a.75.75 0 0 0 .747-.695v-.002l2-27.999c.001-.014-.008-.025-.008-.039l.001-.032a.739.739 0 0 0-.073-.322l.002.004zm-4.174 3.202-14.716 16.82-8.143-3.758zM12.75 28.611v-4.823l4.315 1.992zm14.58.254-8.32-3.841c-.024-.015-.038-.042-.064-.054l-5.722-2.656 15.87-18.139z"
                  stroke="none"
                />
              </svg>
            </button>
          </div>
        </form>
    </>
  );
};

export default Main;
