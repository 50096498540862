

      import React from 'react';
      import ButtonGroupComponent from "./ButtonGroupComponent";
      
      
      export const sharedHeader = (selectedOption, setSelectedOption) => {
        return (<><h1 style={{marginTop:"3%"}}>QuikGPT</h1>
        <p><i>Generate greetings, wiki, email and messages with AI, powered by ChatGPT</i></p>
        <ButtonGroupComponent setSelectedOption = {setSelectedOption} selectedOption = {selectedOption}/></>);
      };

      const emailTui = (setInputPrompt, selectedOption, setSelectedOption) => {
          return (
          <>
          <div className="container-tui">
            <div className="row-tui header-tui">
            Create the perfect email with AI 🚀
            </div>
            <div className="row-tui">
              <button className="button-tui" onClick={() => {
                setInputPrompt("Negotiate a raise from my manager");
              }}>
                <p>Negotiate a raise from my manager</p>
              </button>
            </div>
            <div className="row-tui">
              <button className="button-tui" onClick={() => {
                setInputPrompt("Out of office email, not feeling well");
              }}>
                <p>Out of office email, not feeling well</p>
              </button>
            </div>
            <div className="row-tui">
              <button className="button-tui" onClick={() => {
                setInputPrompt("General update on XYZ");
              }}>
                <p>General update on XYZ</p>
              </button>
            </div>
          </div>
        </>)
      }

      const messagesTui = (setInputPrompt, selectedOption, setSelectedOption) => {
        return (
        <>
        <div className="container-tui">
          <div className="row-tui header-tui">
            Create the perfect message with AI 🚀
          </div>
          <div className="row-tui">
            <button className="button-tui" onClick={() => {
              setInputPrompt("Short and casual Happy birthday greeting for a friend, add emojis");
            }}>
              <p>Ask a friend how he is feeling</p>
            </button>
          </div>
          <div className="row-tui">
            <button className="button-tui" onClick={() => {
              setInputPrompt("Will be late for the meeting, carry on without me");
            }}>
              <p>Will be late for the meeting, carry on without me</p>
            </button>
          </div>
          <div className="row-tui">
            <button className="button-tui" onClick={() => {
              setInputPrompt("Short message asking friend for dinner tonight");
            }}>
              <p>Short message asking friend for dinner tonight</p>
            </button>
          </div>
        </div>
      </>)
    }

    const greetingsTui = (setInputPrompt, selectedOption, setSelectedOption) => {
        return (
        <>
        <div className="container-tui">
          <div className="row-tui header-tui">
            Create the perfect greeting for friends and family with AI 🚀
          </div>
          <div className="row-tui">
            <button className="button-tui" onClick={() => {
              setInputPrompt("Happy Birthday greeting for a friend");
            }}>
              <p>Happy Birthday greeting for a friend</p>
            </button>
          </div>
          <div className="row-tui">
            <button className="button-tui" onClick={() => {
              setInputPrompt("Congratulating a friend on a new job");
            }}>
              <p>Congratulating a friend on a new job</p>
            </button>
          </div>
          <div className="row-tui">
            <button className="button-tui" onClick={() => {
              setInputPrompt("Wishing your colleague a speedy recovery");
            }}>
              <p>Wishing your colleague a speedy recovery</p>
            </button>
          </div>
        </div>
      </>)
    }
      
    const wikiTui = (setInputPrompt, selectedOption, setSelectedOption) => {
        return (
        <>
        <div className="container-tui">
          <div className="row-tui header-tui">
            Ask any question and get a reply in seconds 🚀
          </div>
          
          <div className="row-tui">
            <button className="button-tui" onClick={() => {
              setInputPrompt("How far is London from Manchester?");
            }}>
              <p>How far is London from Manchester?</p>
            </button>
          </div>

          <div className="row-tui">
            <button className="button-tui" onClick={() => {
              setInputPrompt("How tall is the Empire State building");
            }}>
              <p>How tall is the Empire State building</p>
            </button>
          </div>

          <div className="row-tui">
            <button className="button-tui" onClick={() => {
              setInputPrompt("Rank largest countries by area and output in table format");
            }}>
              <p>Rank largest countries by area and output in table format</p>
            </button>
          </div>
        </div>
      </>)
    }

      const TeachingUI = ({ setInputPrompt, selectedOption, setSelectedOption }) => {
        if(selectedOption === 'wiki'){
            return wikiTui(setInputPrompt, selectedOption, setSelectedOption);
        }
        if(selectedOption === 'greeting'){
            return greetingsTui(setInputPrompt, selectedOption, setSelectedOption);
        }
        return selectedOption === "message" ? messagesTui(setInputPrompt, selectedOption, setSelectedOption): emailTui(setInputPrompt, selectedOption, setSelectedOption);
      };
      
      export default TeachingUI;
      