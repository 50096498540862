import React, { useState } from 'react';
import { Button, ButtonGroup } from 'reactstrap';

const ButtonGroupComponent = ({selectedOption, setSelectedOption}) => {
  

  return (
    <ButtonGroup>
        <Button
      style={{background:selectedOption === 'greeting' ? "cadetblue": "none"}}
        onClick={() => setSelectedOption('greeting')}
      >
        Greetings
      </Button>
      <Button
        // color={selectedOption === 'email' ? 'primary' : 'secondary'}
        style={{background:selectedOption === 'wiki' ? "cadetblue": "none"}}
        onClick={() => setSelectedOption('wiki')}
      >
        Wiki
      </Button>
      <Button
        // color={selectedOption === 'email' ? 'primary' : 'secondary'}
        style={{background:selectedOption === 'email' ? "cadetblue": "none"}}
        onClick={() => setSelectedOption('email')}
      >
        Email
      </Button>
      <Button
      style={{background:selectedOption === 'message' ? "cadetblue": "none"}}
        onClick={() => setSelectedOption('message')}
      >
        Message
      </Button>
      
    </ButtonGroup>
  );
};

export default ButtonGroupComponent;
